import React, { useState, useEffect, useContext } from "react";
import {
	Button,
	Dialog,
	makeStyles,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toast } from "react-toastify";


const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: "100%",
	},
	dialogPaper: {
		minWidth: '50vw',
		maxWidth: '50vw',
	},
	gridContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
}));

const ModalLeadExistente = ({ modalOpen, onClose, leadid }) => {
	const [url, setUrl] = useState('');
	const { user } = useContext(AuthContext);

	const tk = localStorage.getItem("tk");

	const handleClose = () => {
		onClose();
	};

	return (
		<div style={{ background: '#262626', color: 'white' }}>
			{leadid && (
				<Dialog open={modalOpen} onClose={onClose} fullWidth maxWidth="lg">
					<DialogContent>
						<iframe
							src={`https://gosellgestao.com.br/home2/modalvisualizarleadexterna?idlead=${leadid}&tk=${tk}`}
							width="100%"
							height="800px"
							style={{ border: 'none' }}
							title={`Card #${leadid}`}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={onClose}
							color="primary"
							variant="outlined"
						>Fechar</Button>
					</DialogActions>
				</Dialog>
			)}

		</div>
	);
};

export default ModalLeadExistente;