import React, { useState, useEffect, useContext } from "react";
import {
	Button,
	Dialog,
	makeStyles,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toast } from "react-toastify";


const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: "100%",
	},
	dialogPaper: {
		minWidth: '50vw',
		maxWidth: '50vw',
	},
	gridContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
}));

const Lead = ({ modalOpen, onClose, ticketid, onLeadCreated }) => {
	const [ticketLeadVinculado, setTicketLeadVinculado] = useState(null);
	const [url, setUrl] = useState('');
	const { user } = useContext(AuthContext);

	useEffect(() => {
		if (modalOpen) {
			const fetchLeadData = async () => {
				try {
					const response = await api.get(`/lead/${ticketid}`);

					if (response.data.lead_vinculado) {
						setTicketLeadVinculado(response.data.lead_vinculado);
					} else {
						setTicketLeadVinculado(null);
					}

					setUrl(response.data.url);
				} catch (err) {
					toastError(err);
				}
			};

			fetchLeadData();
		}
	}, [modalOpen, ticketid]);

	const handleSaveLead = async () => {
		if (!ticketid) return;
		try {
			const response = await api.post("/lead", { ticketid });
			if (response.data.sucesso) {
				toast.success("Vínculo salvo com sucesso");
				setTicketLeadVinculado(response.data.lead.id);
				
				if (onLeadCreated) {
					onLeadCreated(response.data.lead);
				}
			} else {
				toast.error("Erro ao salvar vínculo");
			}
		} catch (error) {
			toast.error("Erro ao salvar vínculo: " + error.message);
		} finally {
			onClose();
		}
	};

	const handleClose = () => {
		onClose();
	};

	return (
		<div style={{ background: '#262626', color: 'white' }}>
			{!ticketLeadVinculado && (
				<Dialog open={modalOpen} onClose={() => handleClose(false)} fullWidth maxWidth="xs">
					<DialogTitle style={{ background: '#262626', color: 'white' }}>Confirmação</DialogTitle>
					<DialogContent style={{ background: '#262626', color: 'white' }}>
						<p>Deseja abrir um card vinculado a este ticket?</p>
					</DialogContent>
					<DialogActions style={{ background: '#262626' }}>
						<Button onClick={onClose} color="secondary" variant="contained">Não</Button>
						<Button onClick={handleSaveLead} color="primary" variant="contained">Sim</Button>
					</DialogActions>
				</Dialog>
			)}

		</div>
	);
};

export default Lead;